







































import {Vue, Component} from "vue-property-decorator";
import {TemplateMess} from "@/request/plat/TemplateMess";

@Component({
    name: 'plat_templateMess'
})
export default class plat_templateMess extends Vue {
    merchantId: string = '';
    loading: boolean = false;
    loading2: boolean = false;
    dataList: Array<any> = [];
    currentTemplateMessage: any = {};
    currentKeys: Array<any> = [];
    currentSelectedInd: number = 0;
    doSearch() {
        if (this.merchantId === '' || this.merchantId === undefined) return;
        this.loading = true;
        TemplateMess.list({merchantId: this.merchantId}).then((body: any) => {
            this.loading = false;
            if (body.code === 0) {
                this.dataList = body.data.list;
                this.selected(this.dataList[this.currentSelectedInd], this.currentSelectedInd);
            }
        })
    }
    selected(item: any, ind: number) {
        this.currentTemplateMessage = item;
        this.currentSelectedInd = ind;
        this.currentKeys = [];
        let keys = item.messKeys;
        for (let p in keys) if (p.indexOf('Value') === -1) this.currentKeys.push({label: keys[`${p}Value`], model: keys[p], key: p})
    }
    submit() {
        let keys: any = {};
        this.currentKeys.forEach((j: any) => keys[j.key] = j.model);
        let params: any = {
            templateId: this.currentTemplateMessage.templateId,
            desc: this.currentTemplateMessage.desc,
            funcType: this.currentTemplateMessage.funcType,
            merchantId: this.merchantId,
            messKeys: keys,
            type: this.currentTemplateMessage.type
        };
        this.loading2 = true;
        TemplateMess.save(params).then((body: any) => {
            this.loading2 = false;
            this.doSearch();
        })
    }
}
